import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/Layout"
import Metadata from "../../components/Metadata"
import { useTranslation } from 'gatsby-plugin-react-i18next';

import '../blog.css'

const BlogPostExcerpt = ({excerpt, frontmatter, slug, dateText}) => (
  <div className="card card-blog mb-3">
    <Link to={slug} className="text-decoration-none text-dark">
      <div className="card-body">
        <h5 className="card-title"> {frontmatter.title}{" "}  </h5>
        <p className="card-text"> {excerpt}
          <br />
          <Link to={slug}>
            <svg xmlns="http://www.w3.org/2000/svg" height={20} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </Link>

          </p>
          <p className="card-text">
            <small className="text-muted"> {dateText} </small>
            
        </p>
      </div>
    </Link>
  </div>
)

const BlogIndex = ({ location, data }) => {
  const {t} = useTranslation();

  const excerpts = data.allMarkdownRemark.edges.map(({ node }) => {
    if (node.frontmatter.layout !== 'blog-post') return ''
    return (<BlogPostExcerpt excerpt={node.excerpt} frontmatter={node.frontmatter} dateText={!node.frontmatter.update ? node.frontmatter.date : `${t('Mis à jour le')} ${node.frontmatter.update}`} slug={node.fields.slug} key={node.id}/>)
  })

  return (
    <Layout>
      <Metadata
        pathname={location.pathname}
        title="Blog"
        description="Les meilleurs articles sur le cinéma, les films, dessins animés, documentaires, les meilleures ressources, le domaine public etc."
      />
      <div className="bg-grey">
        <h1 className="blog-title">Blog</h1>
        <div className="container-fluid my-4 blog">
          <div className="row">
            {/* <h4>{data.allMarkdownRemark.totalCount} Posts</h4> */}
            <div className="col-md-7 col-12 m-auto">
                    
              {excerpts}
                    
            </div>
            {/* <div className="col-md-3">
              <h5>Articles récents</h5>
              <ul className="list-unstyled">
                <li className="mb-2"> <a className="text-dark" href="<%- url_for(post.path) %>"></a> </li>
              </ul>
              <h5 className="mt-5">Mis en avant</h5>
              <ul className="list-unstyled">
                <li className="mb-2"> <a className="text-dark" href="<%- url_for(post.path) %>"> </a> </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const query = graphql`
  query($language: String!) {
    allMarkdownRemark(
      filter: {frontmatter: {lang: {eq: $language}, published: {eq: true}}}
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            layout
            date(locale: $language)
            update(locale: $language)
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`